import React from 'react';

const Setup = ({ setSetting, setting, getTotalWorkoutTime, language }) => {
  const setTotalTabata = (e) => {
    let tabata = setting.totalTabata;
    if (e.target.name === '-') {
      if (tabata - 1 >= 0) {
        setSetting({ ...setting, totalTabata: tabata - 1 });
      }
    } else if (e.target.name === '+') {
      setSetting({ ...setting, totalTabata: tabata + 1 });
    }
  };

  const setTotalRound = (e) => {
    let round = setting.totalRound;
    if (e.target.name === '-') {
      if (round - 1 >= 0) {
        setSetting({ ...setting, totalRound: round - 1 });
      }
    } else if (e.target.name === '+') {
      setSetting({ ...setting, totalRound: round + 1 });
    }
  };

  const setTabataRestTime = (e) => {
    let restTime = setting.tabataRestTime
      .split(':')
      .reduce((acc, curr) => Number(acc) * 60 + Number(curr));

    if (e.target.name === '-') {
      if (restTime - 5 >= 0) {
        let totalMinutes = getTotalMinutes(restTime - 5);
        let restSecs = (restTime - 5) % 60;

        setSetting({
          ...setting,
          tabataRestTime: `${
            totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes
          }:${restSecs < 10 ? `0${restSecs}` : restSecs}`,
        });
      }
    } else if (e.target.name === '+') {
      let totalMinutes = getTotalMinutes(restTime + 5);
      let restSecs = (restTime + 5) % 60;

      setSetting({
        ...setting,
        tabataRestTime: `${
          totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes
        }:${restSecs < 10 ? `0${restSecs}` : restSecs}`,
      });
    }
  };

  const setRestTime = (e) => {
    let restTime = setting.exerciseRestTime
      .split(':')
      .reduce((acc, curr) => Number(acc) * 60 + Number(curr));

    if (e.target.name === '-') {
      if (restTime - 5 >= 0) {
        let totalMinutes = getTotalMinutes(restTime - 5);
        let restSecs = (restTime - 5) % 60;

        setSetting({
          ...setting,
          exerciseRestTime: `${
            totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes
          }:${restSecs < 10 ? `0${restSecs}` : restSecs}`,
        });
      }
    } else if (e.target.name === '+') {
      let totalMinutes = getTotalMinutes(restTime + 5);
      let restSecs = (restTime + 5) % 60;

      setSetting({
        ...setting,
        exerciseRestTime: `${
          totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes
        }:${restSecs < 10 ? `0${restSecs}` : restSecs}`,
      });
    }
  };

  const setExerciseTime = (e) => {
    let exerciseTime = setting.exerciseTime
      .split(':')
      .reduce((acc, curr) => Number(acc) * 60 + Number(curr));

    if (e.target.name === '-') {
      if (exerciseTime - 5 >= 0) {
        let totalMinutes = getTotalMinutes(exerciseTime - 5);
        let restSecs = (exerciseTime - 5) % 60;

        setSetting({
          ...setting,
          exerciseTime: `${
            totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes
          }:${restSecs < 10 ? `0${restSecs}` : restSecs}`,
        });
      }
    } else if (e.target.name === '+') {
      let totalMinutes = getTotalMinutes(exerciseTime + 5);
      let restSecs = (exerciseTime + 5) % 60;

      setSetting({
        ...setting,
        exerciseTime: `${
          totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes
        }:${restSecs < 10 ? `0${restSecs}` : restSecs}`,
      });
    }
  };

  const setPrepTime = (e) => {
    let prepTime = setting.prepTime
      .split(':')
      .reduce((acc, curr) => Number(acc) * 60 + Number(curr));

    if (e.target.name === '-') {
      if (prepTime - 5 >= 0) {
        let totalMinutes = getTotalMinutes(prepTime - 5);
        let restSecs = (prepTime - 5) % 60;

        setSetting({
          ...setting,
          prepTime: `${totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes}:${
            restSecs < 10 ? `0${restSecs}` : restSecs
          }`,
        });
      }
    } else if (e.target.name === '+') {
      let totalMinutes = getTotalMinutes(prepTime + 5);
      let restSecs = (prepTime + 5) % 60;

      setSetting({
        ...setting,
        prepTime: `${totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes}:${
          restSecs < 10 ? `0${restSecs}` : restSecs
        }`,
      });
    }
  };

  const getTotalMinutes = (sec) => {
    let minutes = sec >= 60 ? 1 : 0;
    let seconds = sec;

    while (seconds - 60 >= 60) {
      seconds = seconds - 60;
      minutes++;
    }

    return minutes;
  };

  return (
    <div
      style={{
        marginTop: '20px',
        backgroundColor: '#f2f2f2',
        padding: '10px',
        borderRadius: '12px',
        border: '1px solid #ccc',
      }}
    >
      <div style={inputContainer}>
        <div style={inputStyle}>
          <div style={titleStyle}>{language ? '준비 시간' : 'Ready time'}</div>
          <div style={inputBoxStyle}>{setting.prepTime}</div>
        </div>
        <div style={buttonStyle}>
          <button
            style={minusButton}
            name='-'
            onClick={setPrepTime}
          >
            -
          </button>
          <button
            style={plusButton}
            name='+'
            onClick={setPrepTime}
          >
            +
          </button>
        </div>
      </div>
      <div style={inputContainer}>
        <div style={inputStyle}>
          <div style={titleStyle}>
            {language ? '운동 시간' : 'Exercise time'}
          </div>
          <div style={inputBoxStyle}>{setting.exerciseTime}</div>
        </div>
        <div style={buttonStyle}>
          <button
            style={minusButton}
            name='-'
            onClick={setExerciseTime}
          >
            -
          </button>
          <button
            style={plusButton}
            name='+'
            onClick={setExerciseTime}
          >
            +
          </button>
        </div>
      </div>
      <div style={inputContainer}>
        <div style={inputStyle}>
          <div style={titleStyle}>
            {language ? '운동 휴식 시간' : 'Rest in exercises'}
          </div>
          <div style={inputBoxStyle}>{setting.exerciseRestTime}</div>
        </div>
        <div style={buttonStyle}>
          <button
            style={minusButton}
            name='-'
            onClick={setRestTime}
          >
            -
          </button>
          <button
            style={plusButton}
            name='+'
            onClick={setRestTime}
          >
            +
          </button>
        </div>
      </div>
      <div style={inputContainer}>
        <div style={inputStyle}>
          <div style={titleStyle}>{language ? '운동' : 'Exercise'}</div>
          <div style={inputBoxStyle}>{setting.totalRound}</div>
        </div>
        <div style={buttonStyle}>
          <button
            style={minusButton}
            name='-'
            onClick={setTotalRound}
          >
            -
          </button>
          <button
            style={plusButton}
            name='+'
            onClick={setTotalRound}
          >
            +
          </button>
        </div>
      </div>
      <div style={inputContainer}>
        <div style={inputStyle}>
          <div style={titleStyle}>
            {language ? '세트 휴식 시간' : 'Rest in sets'}
          </div>
          <div style={inputBoxStyle}>{setting.tabataRestTime}</div>
        </div>
        <div style={buttonStyle}>
          <button
            style={minusButton}
            name='-'
            onClick={setTabataRestTime}
          >
            -
          </button>
          <button
            style={plusButton}
            name='+'
            onClick={setTabataRestTime}
          >
            +
          </button>
        </div>
      </div>
      <div style={inputContainer}>
        <div style={inputStyle}>
          <div style={titleStyle}>{language ? '세트' : 'Set'}</div>
          <div style={inputBoxStyle}>{setting.totalTabata}</div>
        </div>
        <div style={buttonStyle}>
          <button
            style={minusButton}
            name='-'
            onClick={setTotalTabata}
          >
            -
          </button>
          <button
            style={plusButton}
            name='+'
            onClick={setTotalTabata}
          >
            +
          </button>
        </div>
      </div>
      <div style={totalStyle}>
        <div>{language ? '총 운동 시간' : 'Total workout'}</div>{' '}
        <div style={{ color: 'orange', marginLeft: '10px' }}>
          {getTotalWorkoutTime()}
        </div>
      </div>
    </div>
  );
};

const inputContainer = {
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
};

const inputStyle = {
  backgroundColor: '#f1f1f1',
  padding: '5px 24px',
  borderRadius: '8px',
  border: '1px solid #d8d8d8',
  fontWeight: '200',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  width: '70%',
  marginRight: '10px',
};

const titleStyle = {
  fontSize: '4vw',
};

const inputBoxStyle = {
  border: 'none',
  background: 'transparent',
  fontSize: '5vw',
  textAlign: 'right',
};

const buttonStyle = {
  padding: '5px',
  border: '1px solid #d8d8d8',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  position: 'relative',
  width: '30%',
};

const minusButton = {
  fontSize: '6vw',
  border: 'none',
  backgroundColor: 'transparent',
  borderRight: '1px solid #d8d8d8',
  position: 'relative',
  width: '50%',
  outline: 'none',
};

const plusButton = {
  fontSize: '6vw',
  border: 'none',
  backgroundColor: 'transparent',
  position: 'relative',
  width: '50%',
  outline: 'none',
};

const totalStyle = {
  fontSize: '8vw',
  fontWeight: '700',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default Setup;
