import React from 'react';
import Running from './components/running';
import Setup from './components/setup';

function App() {
  const [setting, setSetting] = React.useState({
    totalTabata: 4,
    totalRound: 3,
    exerciseTime: '00:20',
    exerciseRestTime: '00:10',
    prepTime: '01:00',
    tabataRestTime: '00:30',
  });
  const [language, setLanguage] = React.useState(0);

  React.useEffect(() => {
    let localLanguage = localStorage.getItem('language');
    if (localLanguage !== null) {
      setLanguage(Number(localLanguage));
    }
  }, []);

  const changeLanguage = () => {
    let lang = language ? 0 : 1;
    localStorage.setItem('language', lang);
    setLanguage(lang);
  };

  const getTotalWorkoutTime = () => {
    let exerciseTime = setting.exerciseTime
      .split(':')
      .reduce((acc, curr) => Number(acc) * 60 + Number(curr));
    let exerciseRestTime = setting.exerciseRestTime
      .split(':')
      .reduce((acc, curr) => Number(acc) * 60 + Number(curr));
    let prepTime = setting.prepTime
      .split(':')
      .reduce((acc, curr) => Number(acc) * 60 + Number(curr));
    let tabataRestTime = setting.tabataRestTime
      .split(':')
      .reduce((acc, curr) => Number(acc) * 60 + Number(curr));
    let totalExerciseTime =
      setting.totalTabata *
        setting.totalRound *
        (exerciseTime + exerciseRestTime) +
      setting.totalTabata * tabataRestTime +
      prepTime;

    let totalMinutes = getTotalMinutes(totalExerciseTime);
    let restSecs = totalExerciseTime % 60;

    return `${totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes}:${
      restSecs < 10 ? `0${restSecs}` : restSecs
    }`;
  };

  const getTotalMinutes = (sec) => {
    let minutes = sec >= 60 ? 1 : 0;
    let seconds = sec;

    while (seconds - 60 >= 60) {
      seconds = seconds - 60;
      minutes++;
    }

    return minutes;
  };

  return (
    <div style={{ fontFamily: "'Noto Sans KR', sans-serif" }}>
      <Running
        setting={setting}
        setSetting={setSetting}
        getTotalWorkoutTime={getTotalWorkoutTime}
        language={language}
      />
      <Setup
        setting={setting}
        setSetting={setSetting}
        getTotalWorkoutTime={getTotalWorkoutTime}
        language={language}
      />
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          margin: '24px 0 12px',
          fontWeight: 'bold',
          fontSize: '2vw',
        }}
      >
        © 2023 Workout Timer
      </div>
      <div style={{ width: '100%', textAlign: 'center', marginBottom: '36px' }}>
        <span
          style={{
            cursor: 'pointer',
            fontWeight: language ? 'bold' : 'normal',
            fontSize: '4vw',
          }}
          onClick={changeLanguage}
        >
          kor
        </span>{' '}
        <span style={{ fontSize: '3vw' }}>|</span>{' '}
        <span
          style={{
            cursor: 'pointer',
            fontWeight: language ? 'normal' : 'bold',
            fontSize: '4vw',
          }}
          onClick={changeLanguage}
        >
          eng
        </span>
      </div>
    </div>
  );
}

export default App;
