import React, { useRef } from 'react';
import countdownBeepAudio from '../assets/countdown_beep.mp3';
import startBeepAudio from '../assets/start_beep.mp3';
import startAudio from '../assets/start.mp3';
import workoutDoneAudio from '../assets/workout_done.mp3';

const audioSound = {
  countdownBeep: countdownBeepAudio,
  startBeep: startBeepAudio,
  start: startAudio,
  workoutDone: workoutDoneAudio,
};

let setAudioSound = false;

const Running = ({ setting, getTotalWorkoutTime, language, setSetting }) => {
  // const prepTimerRef = useRef(null);
  // const exerciseTimerRef = useRef(null);
  // const restInRoundTimerRef = useRef(null);
  // const restInSetTimerRef = useRef(null);

  // const prepTimeRef = useRef(5);
  // const exerciseTimeRef = useRef(10);
  // const restInRoundTimeRef = useRef(10);
  // const restInSetTimeRef = useRef(10);

  // const countdownBeep = useSound('countdownBeep');
  // const startBeep = useSound('startBeep');
  // const start = useSound('start');
  // const workoutDone = useSound('workoutDone');
  const [status, setStatus] = React.useState('READY');
  const [started, setStarted] = React.useState(false);
  const [pausedAt, setPausedAt] = React.useState('');
  const [runPrepTime, setRunPrepTime] = React.useState(
    setting.prepTime
      .split(':')
      .reduce((acc, curr) => Number(acc) * 60 + Number(curr))
  );
  const [runExerciseTime, setRunExerciseTime] = React.useState(
    setting.exerciseTime
      .split(':')
      .reduce((acc, curr) => Number(acc) * 60 + Number(curr))
  );
  const [runRestTime, setRunRestTime] = React.useState(
    setting.exerciseRestTime
      .split(':')
      .reduce((acc, curr) => Number(acc) * 60 + Number(curr))
  );
  const [runTabataRestTime, setRunTabataRestTime] = React.useState(
    setting.tabataRestTime
      .split(':')
      .reduce((acc, curr) => Number(acc) * 60 + Number(curr))
  );
  const [round, setRound] = React.useState(0);
  const [tabata, setTabata] = React.useState(0);

  React.useEffect(() => {
    setRunPrepTime(
      setting.prepTime
        .split(':')
        .reduce((acc, curr) => Number(acc) * 60 + Number(curr))
    );
    setRunExerciseTime(
      setting.exerciseTime
        .split(':')
        .reduce((acc, curr) => Number(acc) * 60 + Number(curr))
    );
    setRunRestTime(
      setting.exerciseRestTime
        .split(':')
        .reduce((acc, curr) => Number(acc) * 60 + Number(curr))
    );
    setRunTabataRestTime(
      setting.tabataRestTime
        .split(':')
        .reduce((acc, curr) => Number(acc) * 60 + Number(curr))
    );
  }, [
    setting.prepTime,
    setting.exerciseTime,
    setting.exerciseRestTime,
    setting.tabataRestTime,
  ]);

  React.useEffect(() => {
    let runPrep;

    if (status === 'PREP') {
      console.log('prep started');
      // prep timer
      let prepTime = runPrepTime;
      setRunPrepTime(prepTime);
      setRound(0);
      setTabata(0);

      // prepTimerRef.current = setInterval(() => {
      //   prepTimeRef.current = prepTimeRef.current - 1;

      //   if (prepTimeRef.current === 3 || prepTimeRef.current === 2 || prepTimeRef.current === 1) {
      //     // let sound = new Audio('/countdown_beep.mp3');
      //     // sound.play();
      //     countdownBeep.play();
      //   } else if (prepTimeRef.current === 0) {
      //     // let sound = new Audio('/start.mp3');
      //     // sound.play();
      //     start.play();
      //   } else if (prepTimeRef.current === -1) {
      //     clearInterval(prepTimerRef.current);
      //     // setRunPrepTime(setting.prepTime.split(':').reduce((acc, curr) => Number(acc) * 60 + Number(curr)));
      //     // setRound((prev) => prev + 1);
      //     // setTabata((prev) => prev + 1);
      //     // setStatus('EXERCISE');
      //   }
      // }, 1000);

      runPrep = setInterval(() => {
        prepTime--;
        setRunPrepTime(prepTime);

        if (prepTime === 3 || prepTime === 2 || prepTime === 1) {
          (async () => {
            await audioSound.countdownBeep.play();
          })();
        } else if (prepTime === 0) {
          (async () => await audioSound.start.play())();
        } else if (prepTime === -1) {
          clearInterval(runPrep);
          setRunPrepTime(
            setting.prepTime
              .split(':')
              .reduce((acc, curr) => Number(acc) * 60 + Number(curr))
          );
          setRound((prev) => prev + 1);
          setTabata((prev) => prev + 1);
          setStatus('EXERCISE');
        }
      }, 1000);
    } else if (status === 'PAUSE') {
      clearInterval(runPrep);
    }

    return () => {
      clearInterval(runPrep);
    };
    // eslint-disable-next-line
  }, [status]);

  React.useEffect(() => {
    let runExercise;

    if (status === 'EXERCISE') {
      console.log('exercise timer');
      // exercise timer
      let exerciseTime = runExerciseTime;
      setRunExerciseTime(exerciseTime);
      runExercise = setInterval(() => {
        exerciseTime--;
        setRunExerciseTime(exerciseTime);

        if (exerciseTime === 3 || exerciseTime === 2 || exerciseTime === 1) {
          (async () => {
            await audioSound.countdownBeep.play();
          })();
        } else if (exerciseTime === 0) {
          (async () => await audioSound.startBeep.play())();
        } else if (exerciseTime === -1) {
          clearInterval(runExercise);
          setRunExerciseTime(
            setting.exerciseTime
              .split(':')
              .reduce((acc, curr) => Number(acc) * 60 + Number(curr))
          );

          if (round < setting.totalRound) {
            setStatus('REST');
          } else {
            setStatus('SETREST');
          }
        }
      }, 1000);
    } else if (status === 'PAUSE') {
      clearInterval(runExercise);
    }

    return () => {
      clearInterval(runExercise);
    };

    // eslint-disable-next-line
  }, [status]);

  React.useEffect(() => {
    let runRest;

    if (status === 'REST') {
      console.log('rest timer');
      let restTime = runRestTime;
      setRunRestTime(restTime);
      runRest = setInterval(() => {
        restTime--;
        setRunRestTime(restTime);

        if (restTime === 3 || restTime === 2 || restTime === 1) {
          (async () => {
            await audioSound.countdownBeep.play();
          })();
        } else if (restTime === 0) {
          (async () => await audioSound.startBeep.play())();
        } else if (restTime === -1) {
          clearInterval(runRest);
          setRunRestTime(
            setting.exerciseRestTime
              .split(':')
              .reduce((acc, curr) => Number(acc) * 60 + Number(curr))
          );

          if (round < setting.totalRound) {
            setRound((prev) => prev + 1);
            setStatus('EXERCISE');
          }
        }
      }, 1000);
    } else if (status === 'PAUSE') {
      clearInterval(runRest);
    }

    return () => {
      clearInterval(runRest);
    };

    // eslint-disable-next-line
  }, [status]);

  React.useEffect(() => {
    let runSetRest;

    if (status === 'SETREST') {
      console.log('set rest timer');
      let restTime = runTabataRestTime;
      setRunTabataRestTime(restTime);
      runSetRest = setInterval(() => {
        restTime--;
        setRunTabataRestTime(restTime);

        if (restTime === 3 || restTime === 2 || restTime === 1) {
          (async () => {
            await audioSound.countdownBeep.play();
          })();
        } else if (restTime === 0) {
          if (tabata < setting.totalTabata) {
            (async () => await audioSound.start.play())();
          }
        } else if (restTime === -1) {
          clearInterval(runSetRest);
          setRunTabataRestTime(
            setting.tabataRestTime
              .split(':')
              .reduce((acc, curr) => Number(acc) * 60 + Number(curr))
          );

          if (tabata < setting.totalTabata) {
            setTabata((prev) => prev + 1);
            setRound(1);
            setStatus('EXERCISE');
          } else {
            (async () => await audioSound.workoutDone.play())();
            setStatus('DONE');
            setPausedAt('');
            setStarted(false);
          }
        }
      }, 1000);
    } else if (status === 'PAUSE') {
      clearInterval(runSetRest);
    }

    return () => {
      clearInterval(runSetRest);
    };

    // eslint-disable-next-line
  }, [status]);

  const startAndStopTimer = () => {
    if (!setAudioSound) {
      for (let [key, audio] of Object.entries(audioSound)) {
        const sound = new Audio(audio);
        audioSound[key] = sound;
      }
      setAudioSound = true;
    }

    if (!setting.totalTabata) {
      if (language) {
        alert('세트를 설정해 주세요!');
      } else {
        alert('Add one set at least!');
      }
    } else if (!setting.totalRound) {
      if (language) {
        alert('라운드를 설정해 주세요!');
      } else {
        alert('Add one round at least!');
      }
    } else if (started) {
      window.location.reload();
    } else {
      setStarted(true);
      setStatus('PREP');
    }
  };

  const reset = () => {
    setStatus('READY');
    setSetting({
      totalTabata: 1,
      totalRound: 8,
      exerciseTime: '00:20',
      exerciseRestTime: '00:10',
      prepTime: '00:10',
      tabataRestTime: '00:00',
    });
  };

  const pauseAndResumeTimer = () => {
    setPausedAt(status);
    if (status !== 'PAUSE') {
      setStatus('PAUSE');
    } else if (status === 'PAUSE') {
      if (pausedAt === 'PREP') {
        setStatus('PREP');
      } else if (pausedAt === 'EXERCISE') {
        setStatus('EXERCISE');
      } else if (pausedAt === 'REST') {
        setStatus('REST');
      } else if (pausedAt === 'SETREST') {
        setStatus('SETREST');
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#000',
        padding: '24px',
        borderRadius: '12px',
      }}
    >
      <div
        style={{
          backgroundColor:
            status === 'PREP'
              ? '#ffff00'
              : status === 'EXERCISE'
              ? '#00ff00'
              : status === 'REST'
              ? '#ff0000'
              : status === 'SETREST'
              ? 'orange'
              : status === 'PAUSE' && pausedAt === 'PREP'
              ? '#ffff00'
              : status === 'PAUSE' && pausedAt === 'EXERCISE'
              ? '#00ff00'
              : status === 'PAUSE' && pausedAt === 'REST'
              ? '#ff0000'
              : status === 'PAUSE' && pausedAt === 'SETREST'
              ? 'orange'
              : '#fff',
          padding: '36px 8px',
          borderRadius: '8px',
          textAlign: 'center',
          fontSize: '18vw',
          fontWeight: 900,
          marginBottom: '24px',
        }}
      >
        <div style={{ fontSize: '6vw', fontWeight: 700 }}>
          {language
            ? status === 'PREP'
              ? '준비~!'
              : status === 'EXERCISE'
              ? '운동'
              : status === 'REST'
              ? '라운드 휴식'
              : status === 'SETREST'
              ? '세트 휴식'
              : status === 'PAUSE'
              ? '정지'
              : status === 'DONE'
              ? '운동 완료!'
              : '운동 시간'
            : status === 'PREP'
            ? 'Ready~!'
            : status === 'EXERCISE'
            ? 'Exercise'
            : status === 'REST'
            ? 'Rest'
            : status === 'SETREST'
            ? 'Set rest'
            : status === 'PAUSE'
            ? 'Pause'
            : status === 'DONE'
            ? 'Workout Completed!'
            : 'Workout time'}
        </div>
        <div style={{ lineHeight: '1.2' }}>
          {!started
            ? getTotalWorkoutTime()
            : status === 'PREP'
            ? runPrepTime
            : status === 'EXERCISE'
            ? runExerciseTime
            : status === 'REST'
            ? runRestTime
            : status === 'SETREST'
            ? runTabataRestTime
            : status === 'PAUSE' && pausedAt === 'PREP'
            ? runPrepTime
            : status === 'PAUSE' && pausedAt === 'EXERCISE'
            ? runExerciseTime
            : status === 'PAUSE' && pausedAt === 'REST'
            ? runRestTime
            : status === 'PAUSE' && pausedAt === 'SETREST'
            ? runTabataRestTime
            : getTotalWorkoutTime()}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            padding: '10px',
            borderRadius: '8px',
            textAlign: 'center',
            fontSize: '10vw',
            fontWeight: 900,
            marginBottom: '24px',
            width: '50%',
            marginRight: '12px',
          }}
        >
          <div style={{ lineHeight: '1.2' }}>
            {round} / {setting.totalRound}
          </div>
          <div style={{ fontSize: '5vw', fontWeight: 400 }}>
            {language ? '운동' : 'Exercise'}
          </div>
        </div>
        <div
          style={{
            backgroundColor: '#fff',
            padding: '10px',
            borderRadius: '8px',
            textAlign: 'center',
            fontSize: '10vw',
            fontWeight: 900,
            marginBottom: '24px',
            width: '50%',
            marginLeft: '12px',
          }}
        >
          <div style={{ lineHeight: '1.2' }}>
            {tabata} / {setting.totalTabata}
          </div>
          <div style={{ fontSize: '5vw', fontWeight: 400 }}>
            {language ? '세트' : 'Set'}
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <button
          style={{ ...buttonStyle, marginRight: started ? '12px' : '0px' }}
          onClick={startAndStopTimer}
        >
          {language
            ? started
              ? '운동 끝내기'
              : status === 'DONE'
              ? '한번 더!'
              : '운동 시작'
            : started
            ? 'Finish'
            : status === 'DONE'
            ? 'Restart!'
            : 'Start'}
        </button>
        {started ? (
          <button
            style={{ ...buttonStyle, marginLeft: '12px' }}
            onClick={pauseAndResumeTimer}
          >
            {language
              ? status !== 'PAUSE'
                ? '정지'
                : '계속하기'
              : status !== 'PAUSE'
              ? 'Pause'
              : 'Resume'}
          </button>
        ) : status === 'DONE' ? (
          <button
            style={{ ...buttonStyle, marginLeft: '12px' }}
            onClick={reset}
          >
            {language ? '재설정' : 'Reset'}
          </button>
        ) : null}
      </div>
    </div>
  );
};

const buttonStyle = {
  fontSize: '5vw',
  border: '1px solid #aaa',
  backgroundColor: '#f1f1f1',
  borderRadius: '12px',
  position: 'relative',
  outline: 'none',
  padding: '10px 24px',
  width: '50%',
};

export default Running;
